<template>
  <div class="endesa-count-wrapper">
    <div v-if="count" class="single-gauge-wrapper">
      <div class="gauge-label">
        {{ count.resource_name }}
      </div>
      <vue-gauge
        :key="count.current"
        :refid="'type-unique-id-1'"
        :options="{
          needleValue: getPercentage(true),
          arcDelimiters: [getPercentage(true)],
          chartWidth: chartWidth,
          arcColors: count.capacity ? arcColors : disabledArcColors,
          needleColor: 'black',
          rangeLabel: ['', '']
        }"
      ></vue-gauge>
      <div class="gauge-values">
        {{ count.current }} / {{ count.capacity }} ({{ getPercentage() }}%)
      </div>
      <powered-by-clebex />
    </div>
  </div>
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import VueGauge from "vue-gauge";
import PoweredByClebex from "@/components/global/PoweredByClebex";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";

export default {
  name: "EndesaCount",
  components: {
    VueGauge,
    PoweredByClebex
  },
  data() {
    return {
      count: null,
      arcColors: ["#e44f45", "#52ae43"],
      disabledArcColors: ["#a3a3a3", "#a3a3a3"],
      chartWidth: 300,
      channel: null
    };
  },
  created() {
    this.channel = subscribe(
      "endesa-count",
      ["endesa"],
      wsEvents.endesa.changed,
      this.wsCallback,
      true
    );
  },
  mounted() {
    this.getEndesaCount();
    this.chartWidth = window.innerWidth > 500 ? 500 : window.innerWidth;
  },
  computed: {},
  methods: {
    getEndesaCount() {
      httpServiceAuth
        .get(apiEndpoints.master.endesaCount)
        .then(response => {
          this.count = response.data;
        })
        .catch(() => {
          this.$router.push({ name: "r_login" });
        });
    },
    getPercentage(max) {
      if (!this.count.capacity || !this.count.current) {
        if (max) {
          return 0.01;
        }
        return 0;
      }
      if ((100 * this.count.current) / this.count.capacity > 99.99 && max) {
        return 99.99;
      }
      return ((100 * this.count.current) / this.count.capacity).toFixed(2);
    },
    wsCallback(e) {
      this.count = e;
    }
  },
  beforeUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
      this.channel = null;
    }
  }
};
</script>
